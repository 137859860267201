import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import {
  Columns,
  Left,
  Right,
  StageBlock,
  ContentBlock,
} from '../components/Blocks';
import Layout from '../components/layout';

export default ({ data }: any) => {
  const project = data.allContentfulProjekt.edges[0].node;

  const split = Math.ceil(project.images.length / 1.5);

  return (
    <Layout>
      <ContentStage>
        <Content>
          <Title>{project.title}</Title>
          <Subtitle>{project.subtitle}</Subtitle>
        </Content>
      </ContentStage>

      <StageBlock>
        <GatsbyImage
          fluid={project.hero.fluid}
          alt={project.title}
          style={{
            margin: '0 auto',
            maxWidth: project.hero.fluid.aspectRatio < 1 ? '50%' : undefined,
          }}
        />
      </StageBlock>

      <ContentBlock>
        <Columns>
          <Left>
            {project.images.slice(0, split).map((image: any, index: number) => (
              <GatsbyImage
                style={{
                  marginBottom: '2rem',
                  marginLeft: 'auto',
                  maxWidth: image.fluid.aspectRatio < 1 ? '65%' : undefined,
                }}
                key={index}
                fluid={image.fluid}
                alt={image.title}
              />
            ))}
          </Left>
          <Right>
            <ProjectTextBlock
              dangerouslySetInnerHTML={{
                __html: project.text.childContentfulRichText.html,
              }}
            />

            {project.images.slice(split).map((image: any, index: number) => (
              <GatsbyImage
                style={{
                  marginBottom: '2rem',
                  maxWidth: image.fluid.aspectRatio < 1 ? '65%' : undefined,
                }}
                key={index}
                fluid={image.fluid}
                alt={image.title}
              />
            ))}
          </Right>
        </Columns>
      </ContentBlock>
    </Layout>
  );
};

export const query = graphql`
  query($url: String!) {
    allContentfulProjekt(filter: { url: { eq: $url } }) {
      edges {
        node {
          title
          subtitle
          hero {
            fluid(maxWidth: 1115) {
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
          text {
            childContentfulRichText {
              html
            }
          }
          images {
            title
            fluid(maxWidth: 535) {
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

const Title = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 1rem;

  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    z-index: -1;
    left: 0;
    right: 0;
    height: 20px;
    background: rgba(210, 254, 250, 0.78);
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: 800;
`;

const ProjectTextBlock = styled.div`
  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
  }

  a {
    color: inherit;
  }
`;

export const ContentStage = styled.div`
  clear: both;
  padding: 2rem 5rem;
  padding-top: 0;
  overflow: hidden;

  @media (max-width: 800px) {
    padding: 6rem 2rem 0 2rem;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1115px;
`;
